@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: sylfaen;
  src: url(./assets/fonts/sylfaen.ttf);
}

.font-sylfaen {
  font-family: sylfaen;
}

body {
  @apply bg-white-yale;
  font-family: "Open Sans", sans-serif;
}

img {
  -webkit-user-drag: none;
  user-select: none;
}

.font-title {
  font-size: 54px;
  line-height: 62px;
}

.title-secundary {
  font-size: 56px;
  font-family: sylfaen;
  line-height: 62px;
}

.title-sub {
  font-size: 42px;
  font-family: sylfaen;
  line-height: 62px;
}

.with-transition {
  @apply transition-all duration-500 ease-in-out;
}

@screen lg {
  .font-title {
    font-size: 96px;
    line-height: 96px;
  }

  .title-secundary {
    font-size: 64px;
    font-family: sylfaen;
    line-height: 62px;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
